$(document).ajaxStop($.unblockUI);

const messageReturn = (message, type_message, url) => {
    Swal.fire({
        toast: true,
        position: 'top-end',
        html: message,
        type: type_message,
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    }).then((result) => {
        if (url != '' && url != undefined) {
            window.location.assign(url);
        } else {
            // location.reload();
        }
    });
}

const showLoadingScreen = () =>{
    //include block.js for using this
    $.blockUI({
        message: '<div style="text-align:center;"><div class="c-loader"></div></div>',
        overlayCSS: {
            backgroundColor: "#000",
            opacity: 0.8,
            cursor: "wait",
            '-webkit-border-radius': '5px',
            '-moz-border-radius': '5px'
        },
        css: {
            color: "#000",
            border: 0,
            padding: 0,
            backgroundColor: "transparent"
        },
        onOverlayClick: $.unblockUI
    });
}